.background {
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='277' height='277' viewBox='0 0 200 200'%3E%3Cpolygon fill='%23eef7fa' points='100 0 0 100 100 100 100 200 200 100 200 0'/%3E%3C/svg%3E");
  height: '100vh';
}

.display-none {
  animation: mymove;
  animation-duration: 0.1s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}
@keyframes mymove {
  from {
    height: 100%;
    opacity: 1;
  }

  to {
    height: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 300px;
    z-index: -999;
  }
}

.display {
  animation: mymove2;
  animation-duration: 0.1s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}
@keyframes mymove2 {
  from {
  }

  to {
    height: 100%;
    opacity: 1;
  }
}
