#payment-form {
  align-self: center;
  border-radius: 7px;
  height: 20vh;
  padding: 20px 0;
  width: 100%;
}

#card-element {
  background: white;
  border: 1px solid rgba(50, 50, 93, 0.1);
  border-radius: 4px;
  box-sizing: border-box;
  max-height: 44px;
  padding: 12px;
  width: 45vh;
}

.paybutton {
  background: #304ffe;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 4px 5.5px 0 rgba(0, 0, 0, 0.07);
  color: #fff;
  cursor: pointer;
  display: block;
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
  padding: 12px 16px;
  transition: all 0.2s ease;
  width: 100%;
}

.result-message {
  color: rgb(89, 111, 214);
  font-size: 16px;
  line-height: 20px;
  margin-top: 12px;
  text-align: center;
}

.result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}

.hidden {
  display: none;
}

.card-error {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  margin-top: 12px;
  text-align: center;
}

#payment-request-button {
  margin-bottom: 32px;
}

.paybutton:hover {
  filter: contrast(115%);
}

.paybutton:disabled {
  cursor: default;
  opacity: 0.5;
}

.spinner,
.spinner::before,
.spinner::after {
  border-radius: 50%;
}

.spinner {
  box-shadow: inset 0 0 0 2px;
  color: #fff;
  font-size: 22px;
  height: 20px;
  margin: 0 auto;
  position: relative;
  text-indent: -99999px;
  transform: translateZ(0);
  transform: translateZ(0);
  transform: translateZ(0);
  width: 20px;
}

.spinner::before,
.spinner::after {
  content: '';
  position: absolute;
}

.spinner::before {
  animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
  background: #304ffe;
  border-radius: 20.4px 0 0 20.4px;
  height: 20.4px;
  left: -0.2px;
  top: -0.2px;
  transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  width: 10.4px;
}

.spinner::after {
  animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
  background: #304ffe;
  border-radius: 0 10.2px 10.2px 0;
  height: 10.2px;
  left: 10.2px;
  top: -0.1px;
  transform-origin: 0 10.2px;
  transform-origin: 0 10.2px;
  width: 10.4px;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
  }
}
