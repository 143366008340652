.facebook {
  font-size: 20px;

  margin-right: 10px;
}

.google {
  margin-right: 15px;
  max-height: 20px;
  max-width: 20px;
}

.google-font {
  padding-right: 20px;
}
