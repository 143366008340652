.location {
  top: 150px;
}

.backgroundSide {
  background-image: linear-gradient(to bottom, #f3f7fa, rgba(253, 254, 255, 255));
}

.background {
  background-color: rgba(255, 255, 255, 255);
}

.backgroundHeader {
  background-image: linear-gradient(to bottom, rgba(245, 250, 254, 255), rgba(253, 254, 255, 255));
  border-radius: 5%;
  padding-top: 20px;
}

.background2 {
  background-color: #fafbfd;
}

.background3 {
  background-color: rgba(241, 245, 248, 255);
}

.backgroundBottom {
  background-color: rgba(243, 245, 249, 255);
}

.width {
  width: 100%;
}

.title {
  padding: 0;
  text-align: left;
}
